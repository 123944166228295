import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import LoadCard from '~/modules/symbolPlatform/LoadCard';
import { store } from '~/pages/heineken_template/_private/store';
import { useTimeout, useUpdateEffect } from 'react-use';
import TradingPlatformSymbol from '~/modules/symbolPlatform/base/QuoteBodyBase';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { Button } from '@mantine/core';
import useMedia from '~/hooks/useMedia';
import { BiListCheck } from 'react-icons/bi';
import MutipleDealerQuickFuturesOrderBox from '~/modules/investment-consultant/orderBox/MutipleDealerQuickFuturesOrderBox/MutipleDealerQuickTxFuturesOrderBox';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { weng888_store } from './weng888_store';
import { MutipleFormOpenAccount } from '~/modules/investment-consultant/openAccount/MutipleFormOpenAccount';
export const Weng888_SidePane = memo(function Weng888_SidePane() {
    const { isPc, isPad } = useMedia();
    const power = useSnapshot(weng888_store);
    const symbolState = () => {
        if (power.power === 'long') {
            if (power.trendState === 2) {
                return ['多方趨勢', '積極者沿成本線多方操作，保守者以短支撐附近做多'];
            }
            if (power.trendState === 1) {
                return ['多方支撐', '短線整理，以接多方式操作，等待短支撐出現紫色星號'];
            }
            if (power.trendState === -1) {
                return [
                    '多方拉回',
                    '易出現深度的拉回盤，以出現紅色短支撐為多方操作點，接近收盤時間可以選擇不操作',
                ];
            }
            if (power.trendState === -2) {
                return [
                    '區間震盪',
                    '保守短區間逆勢操作，注意反轉行情。以收過紅色成本線為多方操作點，以綠色短壓力為空方操作點，接近收盤時間可以選擇不操作',
                ];
            }
        }
        if (power.power === 'short') {
            if (power.trendState === -2) {
                return ['空方趨勢', '積極者沿成本線空方操作，保守者以短壓力附近做空'];
            }
            if (power.trendState === -1) {
                return ['空方壓力', '短線整理，以逢高空方式操作，等待短壓力出現綠色星號'];
            }
            if (power.trendState === 1) {
                return [
                    '空方反彈',
                    '易出現快速的反彈盤，以出現紅色短支撐為多方操作點，接近收盤時間可以選擇不操作',
                ];
            }
            if (power.trendState === 2) {
                return [
                    '區間震盪',
                    '保守短區間逆勢操作，注意軋空行情。以跌破綠色成本線為空方操作點，以紅色短支撐為多方操作點，接近收盤時間可以選擇不操作',
                ];
            }
        }
    };
    const symbolStateColor = () => {
        if (power.power === 'long') {
            return '#ca3400';
        }
        else if (power.power === 'short') {
            return '#34aa34';
        }
        else
            return '#454545';
    };
    return (<styleds.container>
      <styleds.state.container>
        <span css={fill_horizontal_all_center}>盤勢狀態</span>
        <styleds.state.content css={css `
            background-color: ${symbolStateColor()};
          `}>
          {symbolState()?.[0]}
        </styleds.state.content>
      </styleds.state.container>
      <div css={css `
          ${fill_horizontal_cross_center};
          height: 64px;
          background-color: ${symbolStateColor()}27;
          border-radius: 4px;
          padding: 4px;
          font-size: 14px;
        `}>
        {symbolState()?.[1]}
      </div>
      <styleds.header>商品觀察清單</styleds.header>
      <div css={css `
          width: 100%;
          height: calc(100% - ${isPc ? 430 : 180}px);
        `}>
        <SymbolListContent groupName='weng888_group_1'/>
      </div>

      <MutipleFormOpenAccount AgentName='weng888'/>
      {isPc && (<styleds.OrderBoxContainer>
          <styleds.OrderBoxTitle>網頁下單</styleds.OrderBoxTitle>
          <styleds.OrderBoxBody>
            <MutipleDealerQuickFuturesOrderBox.Display localStorageAgentName={'weng888'}/>
          </styleds.OrderBoxBody>
        </styleds.OrderBoxContainer>)}
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 4px;
    background-color: #141721;
    border-right: 2px solid #2b2e38;
    gap: 8px;
  `,
    header: styled.div `
    ${fill_horizontal_all_center};
    height: 48px;
    border-radius: 4px;
    background-color: #1d1f28;
  `,
    state: {
        container: styled.div `
      ${fill_horizontal_cross_center};
      height: 40px;
      display: grid;
      grid-template-columns: 30% 70%;
    `,
        content: styled.div `
      ${fill_horizontal_all_center};
      border-radius: 4px;
      font-size: 18px;
      font-weight: bold;
    `,
    },
    OrderBoxContainer: styled.div `
    height: 150px;
    width: 100%;
  `,
    OrderBoxTitle: styled.div `
    ${fill_horizontal_all_center};
    font-size: 14px;
    padding: 8px 0;
    height: 30px;
  `,
    OrderBoxBody: styled.div `
    height: 120px;
    width: 100%;
  `,
};
export const SymbolListContent = memo(function SymbolListContent(props) {
    const charting = useSnapshot(store).charting;
    const { state: watchListState, acts: watchListActs } = useSymbolWatchList(props.groupName);
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const watchListSymbol = watchListState.symbolListArray;
    const hadSymbol = watchListSymbol?.includes(charting.symbol);
    useEffect(() => {
        signalrStore2.addQuote(watchListSymbol);
        return () => {
            signalrStore2.removeQuote(watchListSymbol);
        };
    }, [JSON.stringify(watchListSymbol)]);
    const sortData = useSignalr2QuoteSort(watchListSymbol) ?? [];
    const value = signalrHooks2.useQuotes(sortData);
    /** 載入緩衝時間 */
    const [initializeReady, reset] = useTimeout(1250);
    useUpdateEffect(() => {
        reset();
    }, [initializeReady]);
    const container = css `
      ${fill_vertical_cross_center};
      ${scrollbar2Css};
      gap: 4px;
      padding: 2px;
      &.dark {
        background-color: #141721;
      }
      &.light {
        background-color: #fafafa;
      }

      border-radius: 4px;
    `;
    const buttonBoard = css `
      ${fill_horizontal_all_center};
      display: grid;
      grid-template-columns: 30% 70%;
      height: 30px;
      font-size: 14px;
    `;
    return (<div css={container}>
        <div css={buttonBoard}>
          <span css={css `
              ${fill_horizontal_all_center};
            `}>
            <BiListCheck size={28}/>
            加入清單
          </span>
          <Button css={css `
              width: 100%;
              height: 30px;
            `} variant='gradient' gradient={!hadSymbol ? { from: 'indigo', to: 'cyan' } : { from: 'orange', to: 'red' }} onClick={event => {
            if (hadSymbol) {
                watchListActs.removeSymbol(charting.symbol);
            }
            if (!hadSymbol) {
                watchListActs.addSymbol(charting.symbol, 20);
            }
        }}>
            {!hadSymbol ? '加入' : '移除'}
            {charting.symbol} {dictionary[charting.symbol]}
          </Button>
        </div>

        <TradingPlatformSymbol.Header />
        {watchListSymbol?.length === 0 && initializeReady() ? (<LoadCard.emtpty />) : (watchListSymbol?.length === 0 && <LoadCard.Loading />)}

        {value.map((s, index) => {
            return (<TradingPlatformSymbol.Body key={index} quote={s} watchListSymbol={watchListSymbol} watchlistActs={watchListActs}/>);
        })}
      </div>);
});
